import React, { useState, useEffect } from 'react';

function DeleteContact() {
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('DE'); // Default to German

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage.toUpperCase());
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch('https://noisi-back-qzk2.onrender.com/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => response.text())
        .then(data => alert(data))
        .catch(error => alert(language === 'DE' ? 'Fehler beim Senden der Löschbestätigungs-E-Mail' : 'Error sending deletion confirmation email'));
    };

    const content = {
        DE: {
            title: 'Vom Newsletter abmelden',
            placeholder: 'Gib deine E-Mail ein',
            button: 'Löschen',
        },
        EN: {
            title: 'Unsubscribe from Newsletter',
            placeholder: 'Enter your email',
            button: 'Delete',
        }
    };

    const currentContent = content[language];

    return (
        <div style={{ backgroundColor: '#111', height: '100vh', width: '100vw', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/NOiSi%20Events%20Kopie-Photoroom.png?alt=media&token=fbfc6479-8ad8-452d-9748-a67e59fd6dc4"
                alt="Foto"
                style={{ width: '20%' }}
            />
            <h1 style={{ fontWeight: 'lighter', fontFamily: 'Montserrat' }}>{currentContent.title}</h1>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={currentContent.placeholder}
                    required
                    style={{ padding: '10px', margin: '10px 0', borderRadius: '5px', border: 'none', width: '80%', maxWidth: '300px', fontFamily: 'Montserrat', fontSize: '18px' }}
                />
                <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#333', color: 'white', cursor: 'pointer', maxWidth: '300px', fontFamily: 'Montserrat', fontSize: '14px' }}>
                    {currentContent.button}
                </button>
            </form>
        </div>
    );
}

export default DeleteContact;
