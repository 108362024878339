import React, { useState, useEffect } from 'react';
import { NavBar, Footer } from '../components';
import { FaFlagUsa, FaFlagDe } from 'react-icons/fa';
// https://www.stephanbodzin.de

function Home() {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;
  const [language, setLanguage] = useState('DE'); // Default to German

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage.toUpperCase());
    }
  }, []);

  const content = {
    DE: {
      welcome: 'Willkommen bei Noisi Events!',
      paragraph1: 'Willkommen bei Noisi Events! Hier verschmelzen Afro House und Tech House zu einem unvergleichlichen Erlebnis. Mit den talentierten DJs Radian Music und Niki B bieten wir dir die besten Beats und eine unvergessliche Nacht.',
      paragraph2: 'Tauche ein in die Welt von DJ Radian, der mit marokkanischen und persischen Wurzeln die rhythmischen Klänge Afrikas mit der magischen Atmosphäre des Orients kombiniert. Seine Sets sind energetisch und fesselnd – perfekte Zutaten für eine heiße Partynacht.',
      paragraph3: 'An der Seite von Niki B, der mit seinen mitreißenden Tech House Beats jedes Event zum Kochen bringt, schaffen wir bei Noisi Events eine Atmosphäre, die dich sofort auf die Tanzfläche zieht. Gemeinsam sorgen sie dafür, dass jede Party unvergesslich wird.',
      paragraph4: 'Lass dich von der Musik mitreißen und erlebe die Magie unserer Events. Ob in Marbella, Baden Baden oder Karlsruhe – wir bringen die besten Sounds zu dir. Bei Noisi Events geht es nicht nur um Musik, sondern um ein Erlebnis, das alle Sinne anspricht.',
      paragraph5: 'Bereit für eine Nacht voller unvergesslicher Momente? Komm vorbei und feiere mit uns! Wir freuen uns auf dich.',
    },
    EN: {
      welcome: 'Welcome to Noisi Events!',
      paragraph1: 'Welcome to Noisi Events! Here, Afro House and Tech House merge into an unparalleled experience. With the talented DJs Radian Music and Niki B, we offer you the best beats and an unforgettable night.',
      paragraph2: 'Dive into the world of DJ Radian, who combines the rhythmic sounds of Africa with the magical atmosphere of the Orient, thanks to his Moroccan and Persian roots. His sets are energetic and captivating – the perfect ingredients for a hot party night.',
      paragraph3: 'Alongside Niki B, who brings every event to a boil with his captivating Tech House beats, we at Noisi Events create an atmosphere that immediately pulls you onto the dance floor. Together, they ensure that every party becomes unforgettable.',
      paragraph4: 'Let yourself be carried away by the music and experience the magic of our events. Whether in Marbella, Baden Baden, or Karlsruhe – we bring the best sounds to you. At Noisi Events, it’s not just about music, but about an experience that appeals to all your senses.',
      paragraph5: 'Ready for a night full of unforgettable moments? Come by and celebrate with us! We look forward to seeing you.',
    }
  };

  const currentContent = content[language];

  return (
    <div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#111', fontFamily: 'Quicksand' }}>
      <div style={{ position: 'relative', zIndex: 10 }}>
        <NavBar />
      </div>
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', backgroundColor: 'rgba(1,1,1,1)', textAlign: 'center' }}>
        <div 
          style={{
            height: '120vh',
            width: '100vw',
            backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03531.jpg?alt=media&token=0c0efe91-32f4-403b-b588-d94c2337b51d")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
            animation: 'fadeInBackground 2s ease-in-out'
          }}
        >
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/NOiSi%20Events%20Kopie-Photoroom.png?alt=media&token=fbfc6479-8ad8-452d-9748-a67e59fd6dc4"
            alt="Foto"
            style={{
              height: '20%',
              objectFit: 'cover',
              position: 'absolute',
              bottom: '15%',
              left: '50%',
              transform: 'translateX(-50%)',
              animation: 'fadeInImage 3s ease-in-out'
            }}
          />
        </div>
        <div
          style={{
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03646.jpg?alt=media&token=36a08f33-7fcf-4925-accc-61eb8242b8fe")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '20px 20px',
            color: 'white',
            textShadow: '2px 2px 6px rgba(0, 0, 0, 1)'
          }}
        >
          <h1
            style={{
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
              fontSize: 60,
              margin: 0,
              padding: '20px 0'
            }}
          >
            {currentContent.welcome}
          </h1>
          <p
            style={{
              padding: isMobile ? '0 40px' : '0 120px',
              fontFamily: 'Raleway'
            }}
          >
            {currentContent.paragraph1}
          </p>
          <p
            style={{
              padding: isMobile ? '0 40px' : '0 120px',
              fontFamily: 'Raleway'
            }}
          >
            {currentContent.paragraph2}
          </p>
          <p
            style={{
              padding: isMobile ? '0 40px' : '0 120px',
              fontFamily: 'Raleway'
            }}
          >
            {currentContent.paragraph3}
          </p>
          <p
            style={{
              padding: isMobile ? '0 40px' : '0 120px',
              fontFamily: 'Raleway'
            }}
          >
            {currentContent.paragraph4}
          </p>
          <p
            style={{
              padding: isMobile ? '0 40px' : '0 120px',
              fontFamily: 'Raleway'
            }}
          >
            {currentContent.paragraph5}
          </p>
          <div style={{ height: 100 }}></div>
        </div>
      </section>  
      <Footer/>

      <style>
        {`
          @keyframes fadeInBackground {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes fadeInImage {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
}

export default Home;
