import React, { useState, useEffect } from 'react';
import { NavBar, Footer } from '../components';
import { FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../ConsentBanner';

function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [language, setLanguage] = useState('DE'); // Default to German

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage.toUpperCase());
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('click')

    logEvent(analytics, 'send_message', {});

    const url = 'https://noisi-back-qzk2.onrender.com/contact'; // Replace this URL with the actual API URL

    const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    };

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  };

  const content = {
    DE: {
      contactUs: 'Trete mit uns in Kontakt',
      description: 'Wenn Sie Fragen haben oder uns erreichen möchten, verwenden Sie bitte das untenstehende Formular:',
      namePlaceholder: 'Dein Name',
      emailPlaceholder: 'Deine E-Mail',
      messagePlaceholder: 'Deine Nachricht',
      sendButton: 'Senden',
      emailLabel: 'E-Mail:',
    },
    EN: {
      contactUs: 'Get in Touch with Us',
      description: 'If you have any questions or want to reach us, please use the form below:',
      namePlaceholder: 'Your Name',
      emailPlaceholder: 'Your Email',
      messagePlaceholder: 'Your Message',
      sendButton: 'Send',
      emailLabel: 'Email:',
    },
  };

  const currentContent = content[language];

  return (
    <div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#000', fontFamily: 'Quicksand' }}>
      <NavBar />
      <section style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        color: '#EEE', 
        textAlign: 'center', 
        minHeight: '100vh', 
        padding: '10% 10%'
      }}>
        <h1 style={{ marginBottom: '20px', fontSize: '2.5em' }}>{currentContent.contactUs}</h1>
        <p style={{ marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.5' }}>
          {currentContent.description}
        </p>

        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            placeholder={currentContent.namePlaceholder} 
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #444',
              backgroundColor: '#222',
              color: '#EEE',
              fontSize: '1em'
            }}
            required
          />
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder={currentContent.emailPlaceholder} 
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #444',
              backgroundColor: '#222',
              color: '#EEE',
              fontSize: '1em'
            }}
            required
          />
          <textarea 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            placeholder={currentContent.messagePlaceholder} 
            rows="5"
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #444',
              backgroundColor: '#222',
              color: '#EEE',
              fontSize: '1em'
            }}
            required
          />
          <button 
            type="submit" 
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#fff',
              color: '#111',
              fontSize: '1.2em',
              cursor: 'pointer',
              transition: 'background-color 0.3s'
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ddd'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
          >
            {currentContent.sendButton}
          </button>
        </form>

        <div style={{ marginTop: '40px', fontSize: '1.1em' }}>
          <div><strong>{currentContent.emailLabel}</strong> contact@noisi-events.com</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '25px', marginTop: '20px' }}>
          <a 
            href="https://www.instagram.com/noisi_events/" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#EEE', transition: 'color 0.3s' }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#E1306C'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          >
            <FaInstagram size={40} />
          </a>
          <a 
            href="https://www.tiktok.com/@noisi_events" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#EEE', transition: 'color 0.3s' }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#dc4055'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          >
            <FaTiktok size={40} />
          </a>
        </div>
      </section>  
      <Footer/>
    </div>
  );
}

export default Contact;
