// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import Dates from './screens/Dates';
import Contact from './screens/Contact';
import Djs from './screens/Djs';
import {ConsentBanner} from './ConsentBanner';
import VerifyNews from './screens/VerifyNews';
import DeleteContact from './screens/NewsDelete';
import VerifyDelete from './screens/VerifyDelete';
import Recht from './screens/Recht';
import CustomCursor from './CustomCursor';



function App() {
  return (
    <Router>
      <CustomCursor/>

      <ConsentBanner />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dates' element={<Dates />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/djs' element={<Djs />} />
        <Route path='/rechtliches' element={<Recht />} />
        <Route path='/verifynews' element={<VerifyNews />} />
        <Route path='/delete' element={<DeleteContact />} />
        <Route path='/verifydelete' element={<VerifyDelete />} />
      </Routes>
    </Router>
  );
}

export default App;
