// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  cursor: none; /* Versteckt den Standard-Mauszeiger auf Desktop-Geräten */
}

.custom-cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #cfcfcf;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* Spezifisch für Buttons, Links und andere interaktive Elemente */
button, a, input, select, textarea, div, label {
  cursor: none; /* Versteckt den Standard-Mauszeiger auch auf diesen Elementen */
}

@media (hover: none) and (pointer: coarse) {
  /* Auf Geräten wie Smartphones/Touchscreens den Cursor ausblenden */
  .custom-cursor {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/CustomCursor.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,0DAA0D;AAC1E;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,oBAAoB;EACpB,gCAAgC;EAChC,aAAa;AACf;;AAEA,kEAAkE;AAClE;EACE,YAAY,EAAE,gEAAgE;AAChF;;AAEA;EACE,mEAAmE;EACnE;IACE,aAAa;EACf;AACF","sourcesContent":["body {\n  cursor: none; /* Versteckt den Standard-Mauszeiger auf Desktop-Geräten */\n}\n\n.custom-cursor {\n  position: fixed;\n  width: 20px;\n  height: 20px;\n  background-color: #cfcfcf;\n  border-radius: 50%;\n  pointer-events: none;\n  transform: translate(-50%, -50%);\n  z-index: 9999;\n}\n\n/* Spezifisch für Buttons, Links und andere interaktive Elemente */\nbutton, a, input, select, textarea, div, label {\n  cursor: none; /* Versteckt den Standard-Mauszeiger auch auf diesen Elementen */\n}\n\n@media (hover: none) and (pointer: coarse) {\n  /* Auf Geräten wie Smartphones/Touchscreens den Cursor ausblenden */\n  .custom-cursor {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
