import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from './ConsentBanner';


function Loading() {
  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#001F3F",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        fontFamily: 'Quicksand',
        textAlign: 'center',
      }}
    >
      <Oval
        height="100"
        width="100"
        radius="9"
        color="white"
      />
      <h2 style={{ color: 'white' }}>Danke, für Ihre Geduld</h2>
    </div>
  );
}




function Footer() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [language, setLanguage] = useState('DE'); // Default to German

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage.toUpperCase());
    }
  }, []);

  const handleSocialClick = (event, platform) => {
    event.preventDefault();
    logEvent(analytics, 'select_content', {
      content_type: platform,
      item_id: 'Noisi',
    });
    window.open(event.currentTarget.href, '_blank');
  };

  const handleNewsletterSubmit = (event) => {
    event.preventDefault();

    fetch('https://noisi-back-qzk2.onrender.com/register', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log(error));


    
    setSubmitted(true);
    console.log('Newsletter Email:', email);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.reload();
  };


  const content = {
    DE: {
      firstNamePlaceholder: 'Vorname',
      lastNamePlaceholder: 'Nachname',
      emailPlaceholder: 'E-Mail',
      subscribeButton: submitted ? 'Vielen Dank' : 'Abonnieren',
    },
    EN: {
      firstNamePlaceholder: 'First Name',
      lastNamePlaceholder: 'Last Name',
      emailPlaceholder: 'Email',
      subscribeButton: submitted ? 'Thank you' : 'Subscribe',
    }
  };

  const currentContent = content[language];

  return (
    <footer style={{ backgroundColor: '#111', padding: '20px 50px', textAlign: 'center', color: 'white', maxWidth:'100vw' }}>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '25px', marginTop: '20px' }}>
        <a 
          href="https://www.instagram.com/noisi_events/" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: '#EEE', transition: 'color 0.3s' }}
          onMouseEnter={(e) => e.currentTarget.style.color = '#E1306C'}
          onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          onClick={(e) => handleSocialClick(e, 'instagram')}
        >
          <FaInstagram size={40} />
        </a>
{/* 
        <a 
          href="https://m.youtube.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: '#EEE', transition: 'color 0.3s' }}
          onMouseEnter={(e) => e.currentTarget.style.color = '#FF0000'}
          onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          onClick={(e) => handleSocialClick(e, 'youtube')}
        >
          <FaYoutube size={40} />
        </a>
         */}
        <a 
          href="https://www.tiktok.com/@noisi_events" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: '#EEE', transition: 'color 0.3s' }}
          onMouseEnter={(e) => e.currentTarget.style.color = '#dc4055'}
          onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          onClick={(e) => handleSocialClick(e, 'soundcloud')}
        >
          <FaTiktok size={40} />
        </a>
      </div>
      <div style={{ marginTop: '40px' }}>
        <h3>Newsletter</h3>
      <form onSubmit={handleNewsletterSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
  <input 
    type="email" 
    placeholder={currentContent.emailPlaceholder} 
    value={email} 
    onChange={(e) => setEmail(e.target.value)} 
    required 
    style={{ padding: '10px', fontSize: '16px', borderRadius: '5px', border: 'none', width: '80%', maxWidth: '300px' }}
  />
  
  <div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '80%', maxWidth: '300px' }}>
    <input 
      type="checkbox" 
      id="termsCheckbox" 
      required 
      style={{ cursor: 'pointer' }}
    />
    <label htmlFor="termsCheckbox" style={{ fontSize: '14px', cursor: 'pointer' }}>
      Ich habe die <a href="/rechtliches" target="_blank" style={{ color: '#007BFF', textDecoration: 'none' }}>Bedingungen</a> gelesen und akzeptiert.
    </label>
  </div>

  <button 
    type="submit" 
    style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: 'none', backgroundColor: '#333', color: 'white', width: '80%', maxWidth: '300px' }}
  >
    {currentContent.subscribeButton}
  </button>
</form>

      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '20px' }}>
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/german.png?alt=media&token=c268bbe2-9d76-44ee-a143-ab4396e3b831" 
          alt="German" 
          style={{ cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', border: language === 'DE' ? '2px solid #555' : '2px solid #EEE' }} 
          onClick={() => handleLanguageChange('DE')} 
        />
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/united-states-of-america.png?alt=media&token=771129fd-faa0-4219-b923-c4fd8631f91b" 
          alt="English" 
          style={{ cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', border: language === 'EN' ? '2px solid #555' : '2px solid #EEE' }} 
          onClick={() => handleLanguageChange('EN')} 
        />
      </div>
      <div style={{ marginTop: '40px' }}>
    <strong>Impressum</strong><br />
    Noisi Events GbR<br />
    Seboldstraße 1<br />
    76227 Karlsruhe<br />
    Vertreten durch:<br />
    Soheil Alimoradian<br />
    Niklas Beuscher<br /><br />
    Kontakt<br />
    Telefon: +49 176 64389207<br />
    E-Mail: contact@noisi-events.com<br /><br />
    Redaktionell verantwortlich:<br />
    Soheil Alimoradian<br />
    Niklas Beuscher<br /><br />
    EU-Streitschlichtung<br />
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: 
    <a href="https://ec.europa.eu/consumers/odr/" target="_blank">https://ec.europa.eu/consumers/odr/</a><br />
    Unsere E-Mail-Adresse finden Sie oben im Impressum.<br /><br />
    Verbraucherstreitbeilegung/<br />Universalschlichtungsstelle<br />
    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren<br /> vor einer Verbraucherschlichtungsstelle teilzunehmen.
</div>

    </footer>
  );
}



function NavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 1000;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((item, index) => {
      item.style.animation = 'none'; // Reset animation
      const dummy = item.offsetHeight; // Trigger reflow
      if (showMenu) {
        item.style.animation = `slideIn 0.5s ease forwards ${0.1 * (index + 1)}s`;
      }
    });
  }, [showMenu]);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    if (isHomePage) {
      document.querySelectorAll('.flyInItem').forEach((item, index) => {
        item.style.animation = `flyInItem 1s forwards ${index * 0.1}s`;
      });
    }
  }, [isHomePage]);


  return (
    <div style={{
      position: 'fixed',
      top: 0,
      width: '100vw',
      backgroundColor: 'rgba(17,17,17,0.9)',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
    }}>
      <div>
        <a href="/">
          <img src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/NOiSi%20Events%20Kopie-Photoroom.png?alt=media&token=fbfc6479-8ad8-452d-9748-a67e59fd6dc4" alt="Logo" style={{ height: '100px', marginLeft: 10 }} />
        </a>
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        {!isMobile && (
          <div style={{
  cursor: 'pointer',
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1
}}>
  <style>
    {`
      @keyframes flyIn {
        0% { transform: translateX(100%); }
        100% { transform: translateX(0); }
      }

      .flyInItem {
        opacity: 0;
      }

      @keyframes flyInItem {
        0% { transform: translateX(100%); opacity: 0; }
        100% { transform: translateX(0); opacity: 1; }
      }

      .underline {
        position: relative;
        overflow: hidden;
      }

      .underline::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
      }

      .underline:hover::after {
        transform: translateX(0);
      }
    `}
  </style>
  <a href="/" style={{
    margin: '5px 0',
    color: '#fff',
    textDecoration: 'none',
    padding: '5px',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    fontFamily: 'Arsenal SC',
    fontWeight: 'bold',
    fontSize: 30,
    marginRight: '6vw',
    marginLeft: '200px'
  }} className={`underline ${isHomePage ? 'flyInItem' : ''}`}>HOME</a>
  <a href="/djs" style={{
    margin: '5px 0',
    color: '#fff',
    textDecoration: 'none',
    padding: '5px',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    fontFamily: 'Arsenal SC',
    fontWeight: 'bold',
    fontSize: 30,
    marginRight: '6vw'
  }} className={`underline ${isHomePage ? 'flyInItem' : ''}`}>DJ'S</a>
  <a href="/contact" style={{
    margin: '5px 0',
    color: '#fff',
    textDecoration: 'none',
    padding: '5px',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    fontFamily: 'Arsenal SC',
    fontWeight: 'bold',
    fontSize: 30,
    marginRight: '6vw'
  }} className={`underline ${isHomePage ? 'flyInItem' : ''}`}>CONTACT</a>
  <a href="/dates" style={{
    margin: '5px 0',
    color: '#111',
    textDecoration: 'none',
    padding: '5px',
    paddingRight: '30px',
    paddingLeft: '30px',
    borderRadius: '22px',
    transition: 'background-color 0.3s',
    fontFamily: 'Prompt',
    fontWeight: 'bold',
    fontSize: 30,
    backgroundColor: '#bbb',
    marginRight: '30px'
  }} className={isHomePage ? 'flyInItem' : ''} onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'}
    onMouseOut={(e) => e.target.style.backgroundColor = '#bbb'}>TICKETS</a>
</div>

        )}
      </div>
      {isMobile && (
        <div onClick={toggleMenu} style={{
          cursor: 'pointer',
          padding: '10px'
        }}>
          <div onClick={toggleMenu} style={{
            cursor: 'pointer',
            padding: '10px',
            position: 'fixed',
            top: '30px',
            right: '10px',
          }}>
            <div style={{
              width: '30px',
              height: '4px',
              backgroundColor: '#efefef',
              margin: '4px 0',
              borderRadius: '2px'
            }}></div>
            <div style={{
              width: '30px',
              height: '4px',
              backgroundColor: '#efefef',
              margin: '4px 0',
              borderRadius: '2px'
            }}></div>
            <div style={{
              width: '30px',
              height: '4px',
              backgroundColor: '#efefef',
              margin: '4px 0',
              borderRadius: '2px'
            }}></div>
          </div>
        </div>
      )}
      <div style={{
  transform: showMenu ? 'translateX(0)' : 'translateX(-100%)',
  transition: 'transform 0.3s ease-in-out',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,0.95)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '2px 0px 5px rgba(0,0,0,0.5)'
}}>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 40,
    background: 'rgba(0,0,0,0.8)'
  }} onClick={toggleMenu}>
    <FiX size={30} style={{ margin: 0, color: 'white' }} />
  </div>
  <div style={{
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/NOiSi%20Events%20Kopie-Photoroom.png?alt=media&token=fbfc6479-8ad8-452d-9748-a67e59fd6dc4"
      alt="Foto"
      style={{
        height: '20%',
        objectFit: 'cover',
      }}
    />
    <div style={{ height: '50px' }}></div>
    {[{ name: 'HOME', ref: '/' }, { name: "DJ'S", ref: '/djs' }, { name: 'CONTACT', ref: '/contact' }, { name: 'TICKETS', ref: '/dates' }].map((text, index) => (
      <div
        key={index}
        onClick={() => {
          window.open(text.ref, '_blank');
        }}
        className="menu-item"
        style={{
          color: '#fff',
          textDecoration: 'none',
          padding: '5px',
          borderRadius: text.name === 'TICKETS' ? '22px' : '4px',
          transition: 'background-color 0.3s',
          fontFamily: text.name === 'TICKETS' ? 'Prompt' : 'Arsenal SC',
          fontWeight: 'bold',
          fontSize: 30,
          textAlign: 'center',
          marginBottom: index !== 3 ? '20px' : '0px',
          cursor: 'pointer',
          opacity: 0,
          transform: 'translateY(-50px)',
          backgroundColor: text.name === 'TICKETS' ? '#bbb' : 'transparent',
          color: text.name === 'TICKETS' ? '#111' : '#fff',
          paddingLeft: text.name === 'TICKETS' ? '30px' : '5px',
          paddingRight: text.name === 'TICKETS' ? '30px' : '5px',
        }}
      >
        {text.name}
      </div>
    ))}

    <div style={{ display: 'flex', justifyContent: 'center', gap: '25px', marginTop: '20px' }}>
      <a
        href="https://www.instagram.com/noisi_events/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#EEE', transition: 'color 0.3s' }}
        onMouseEnter={(e) => e.currentTarget.style.color = '#E1306C'}
        onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
      >
        <FaInstagram size={40} />
      </a>

{/*      
      <a
        href="https://m.youtube.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#EEE', transition: 'color 0.3s' }}
        onMouseEnter={(e) => e.currentTarget.style.color = '#FF0000'}
        onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
      >
        <FaYoutube size={40} />
      </a>
       */}
      <a
        href="https://www.tiktok.com/@noisi_events"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#EEE', transition: 'color 0.3s' }}
        onMouseEnter={(e) => e.currentTarget.style.color = '#dc4055'}
        onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
      >
        <FaTiktok size={40} />
      </a>
    </div>
  </div>
</div>


      <style>
        {`
          @keyframes slideIn {
            from {
              transform: translateY(-50px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export { NavBar, Footer, Loading };
