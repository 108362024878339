import React, { useState, useEffect } from 'react';
import { NavBar, Footer } from '../components';
import { FaInstagram, FaYoutube, FaTiktok, FaHome } from 'react-icons/fa';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../ConsentBanner';

const djData = {
  DE: [
    { dj_name: 'Radian Music', ig: 'https://instagram.com/soheil.ofc', yt: 'https://www.youtube.com/@Radian_music',
       //home: 'https://radian-music.com',
        url: 'https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03530.jpg?alt=media&token=4d8561a7-ec44-4e8f-b1ce-11bc989d71e0', text: 'Radian Music ist ein erfahrener DJ, der sich auf Afrohouse spezialisiert hat. Mit Wurzeln in Marokko und Iran bringt er einen einzigartigen und schönen Vibe in seine Sets. Er legt seit seinem 15. Lebensjahr auf und begeistert sein Publikum mit energiegeladenen Beats und einer mitreißenden Atmosphäre.' },
    { dj_name: 'Niki B', ig: 'https://instagram.com', url: 'https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03591.jpg?alt=media&token=6d101507-0a77-48b2-b3cf-3d2b3f6da23a', text: 'Niki B ist bekannt für seine Techhouse-Sets, die perfekt zum Tanzen sind. Er hat auch in jungen Jahren mit dem Auflegen begonnen und begeistert seitdem das Publikum mit seinen rhythmischen und dynamischen Beats. Sein Musikstil ist ideal, um jede Tanzfläche zum Beben zu bringen.' },
  ],
  EN: [
    { dj_name: 'Radian Music', ig: 'https://instagram.com/soheil.ofc', yt: 'https://www.youtube.com/@Radian_music',
       //home: 'https://radian-music.com',
        url: 'https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03530.jpg?alt=media&token=4d8561a7-ec44-4e8f-b1ce-11bc989d71e0', text: 'Radian Music is an experienced DJ specializing in Afrohouse. With roots in Morocco and Iran, he brings a unique and beautiful vibe to his sets. He has been DJing since he was 15 and captivates his audience with energetic beats and an infectious atmosphere.' },
    { dj_name: 'Niki B', ig: 'https://instagram.com', url: 'https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/DSC03591.jpg?alt=media&token=6d101507-0a77-48b2-b3cf-3d2b3f6da23a', text: 'Niki B is known for his Techhouse sets that are perfect for dancing. He started DJing at a young age and has since thrilled audiences with his rhythmic and dynamic beats. His music style is ideal for making any dance floor come alive.' },
  ]
};

function Djs() {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;
  const [language, setLanguage] = useState('DE'); // Default to German

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage.toUpperCase());
    }
  }, []);

  const handleButtonClick = (event, djName, platform) => {
    event.preventDefault();
    logEvent(analytics, 'select_content', {
      content_type: platform,
      item_id: djName,
    });
    window.open(event.currentTarget.href, '_blank');
  };

  const currentDjData = djData[language];

  return (
    <div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#111', fontFamily: 'Quicksand' }}>
      <NavBar />
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', backgroundColor: 'rgba(1,1,1,1)', textAlign: 'center' }}>
        <div style={{ height: '120px' }} />

        {currentDjData.map((dj, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : index % 2 === 0 ? 'row' : 'row-reverse',
              alignItems: 'center',
              margin: '20px 0',
              width: '80%',
              maxWidth: '800px',
              backgroundColor: '#222',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <img
              src={dj.url}
              alt={dj.dj_name}
              style={{
                width: isMobile ? '100%' : '40%',
                height: 'auto',
                borderRadius: '10px',
                margin: isMobile ? '0 0 20px 0' : '0 20px',
                objectFit: 'cover',
              }}
            />
            <div style={{ textAlign: isMobile ? 'center' : 'left', width: '100%' }}>
              <h2 style={{ fontFamily: 'Arsenal SC' }}>{dj.dj_name}</h2>
              <p style={{ fontFamily: 'Raleway' }}>{dj.text}</p>
              <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : index % 2 === 0 ? 'flex-start' : 'flex-end', marginTop: '10px' }}>
                {dj.home && (
                  <a href={dj.home} target="_blank" rel="noopener noreferrer" style={{ color: 'white', margin: '0 10px' }} onClick={(e) => handleButtonClick(e, dj.dj_name, 'home')}>
                    <FaHome size={24} />
                  </a>
                )}
                {dj.ig && (
                  <a href={dj.ig} target="_blank" rel="noopener noreferrer" style={{ color: 'white', margin: '0 10px' }} onClick={(e) => handleButtonClick(e, dj.dj_name, 'instagram')}>
                    <FaInstagram size={24} />
                  </a>
                )}
                {dj.tt && (
                  <a href={dj.tt} target="_blank" rel="noopener noreferrer" style={{ color: 'white', margin: '0 10px' }} onClick={(e) => handleButtonClick(e, dj.dj_name, 'tiktok')}>
                    <FaTiktok size={24} />
                  </a>
                )}
                {dj.yt && (
                  <a href={dj.yt} target="_blank" rel="noopener noreferrer" style={{ color: 'white', margin: '0 10px' }} onClick={(e) => handleButtonClick(e, dj.dj_name, 'youtube')}>
                    <FaYoutube size={24} />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
      <Footer />
    </div>
  );
}

export default Djs;
