// src/screens/VerifyDelete.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function VerifyDelete() {
    const query = useQuery();
    const token = query.get('token');
    const email = query.get('email');
    const [language, setLanguage] = useState('DE'); // Default to German

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage.toUpperCase());
        }
    }, []);

    useEffect(() => {
        if (token && email) {
            fetch(`https://noisi-back-qzk2.onrender.com/delete?token=${token}&email=${email}`)
                .then(response => response.text())
                .catch(error => alert(language === 'DE' ? 'Verifizierung fehlgeschlagen' : 'Verification failed'));
        }
    }, [token, email, language]);

    const content = {
        DE: {
            successMessage: 'Sie haben sich erfolgreich von unserem Newsletter abgemeldet',
        },
        EN: {
            successMessage: 'You successfully unsubscribed from our Newsletter',
        }
    };

    const currentContent = content[language];

    return (
        <div style={{ backgroundColor: '#111', height: '100vh', width: '100vw', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/noisi-3eb2b.appspot.com/o/NOiSi%20Events%20Kopie-Photoroom.png?alt=media&token=fbfc6479-8ad8-452d-9748-a67e59fd6dc4"
                alt="Foto"
                style={{ width: '20%' }}
            />
            <h1 style={{ fontWeight: 'lighter', fontFamily: 'Montserrat' }}>{currentContent.successMessage}</h1>
        </div>
    );
}

export default VerifyDelete;
