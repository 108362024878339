import React from 'react';
import { NavBar, Footer } from '../components';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../ConsentBanner';

function Dates() {
  const dates = [
    {id: '1', date: '28. September 2024', location: "Schloss Eberstein", city: 'Gernsbach, Deutschland', beginning: '17:00', ticketlink: 'https://www.universe.com/events/noisi-events-tickets-R1LDNF'},
  ];

  const backgroundVideo = "https://firebasestorage.googleapis.com/v0/b/noisi-real.appspot.com/o/Schloss%20Eberstein-2-2-2-2-2.mp4?alt=media&token=7aa9289c-0efd-45e8-8847-8608b5718b55";

  const handleTicketClick = (event, index) => {
    event.preventDefault();
    logEvent(analytics, 'ticket_click', {
      event_id: dates[index].id,
      date: dates[index].date,
      location: dates[index].location,
      city: dates[index].city,
      link: dates[index].ticketlink,
    });
    window.open(event.currentTarget.href, '_blank');
  };

  return (
    <div style={{
      minHeight: '100vh', 
      width: '100%', 
      fontFamily: 'Quicksand',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <video 
        autoPlay 
        loop 
        muted 
        playsInline 
        style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100vh', // Ensures full viewport height
          objectFit: 'contain', 
          zIndex: -1,
          backgroundColor:'#111'
        }}>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <NavBar />
      </div>
      <section style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        color: 'white', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker to ensure readability
        textAlign: 'center', 
        minHeight: 'calc(100vh - 80px)', // Adjusted to leave space for the footer
        position: 'relative', 
        zIndex: 1 
      }}>
        <div style={{ marginTop: 100 }}></div>
        {dates.map((date, index) => (
          <div key={date.id} style={{ marginBottom: 50 }}>
            <p style={{ fontSize: '1.3em', fontWeight: 'bolder', fontFamily: 'Prompt' }}>
              {date.date} | {date.beginning} | {date.location} | {date.city}
            </p>
            <a 
              href={date.ticketlink} 
              style={{ color: 'white', textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Prompt' }}
              onClick={(e) => handleTicketClick(e, index)}
            >
              Tickets
            </a>
          </div>
        ))}
        <div style={{ marginTop: 100 }}></div>
      </section>
      <Footer style={{ position: 'relative', zIndex: 1 }} />
    </div>
  );
}

export default Dates;
