import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Select, MenuItem, CircularProgress } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

let analytics;

const ConsentBanner = () => {
  const location = useLocation();
  const [consent, setConsent] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'DE');
  const [storedUserId, setStoredUserId] = useState(localStorage.getItem('userId'));
  const [isLoading, setIsLoading] = useState(true);

  const generateUniqueId = async () => {
    let uniqueId;
    do {
      uniqueId = Math.random().toString(36).substr(2, 9);
    } while ((await isUniqueId(uniqueId)));
    return uniqueId;
  };

  const isUniqueId = async (id) => {
    try {
      const response = await fetch(`https://noisi-back-qzk2.onrender.com/check-id?id=${id}`);
      const result = await response.json();
      console.log(result);
      return result.isUnique; // Erwartet, dass das Backend ein `isUnique`-Feld im JSON zurückgibt
    } catch (error) {
      console.error('Fehler bei der ID-Überprüfung:', error);
      return false;
    }
  };

  const checkAndAddFonts = () => {
    if (!document.querySelector('link[href="https://fonts.googleapis.com/css2?family=Lato&family=Raleway&family=Prompt&family=Montserrat&family=Arsenal+SC&family=Playfair+Display&family=Quicksand&display=swap"]')) {
      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/css2?family=Lato&family=Raleway&family=Prompt&family=Montserrat&family=Arsenal+SC&family=Playfair+Display&family=Quicksand&display=swap';
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  };
  
  // Innerhalb deiner `checkConsentInFirestore`-Funktion:
  const checkConsentInFirestore = async (userId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://noisi-back-qzk2.onrender.com/check-id?id=${userId}`);
      if (response.ok) {
        const consentData = await response.json();
        if (consentData.isUnique) {
          checkAndAddFonts(); // Stelle sicher, dass die Schriftart nur einmal hinzugefügt wird
  
          setConsent(true);
          const app = initializeApp(firebaseConfig);
          analytics = getAnalytics(app);
        }
      } else {
        console.log('Consent not found');
      }
    } catch (error) {
      console.error('Error fetching consent:', error);
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    const checkUserId = async () => {
      let userId = localStorage.getItem('userId');

      if (!userId) {
        userId = await generateUniqueId();
        localStorage.setItem('userId', userId);
      }
      setStoredUserId(userId);
      await checkConsentInFirestore(userId); // Konsent-Überprüfung
    };

    // Nur ausführen, wenn keine Zustimmung erteilt wurde
    if (!consent) {
      checkUserId();
    }

    // Keine Intervalle setzen, wenn keine Änderungen erwartet werden
    if (!consent) {
      const interval = setInterval(() => {
        const currentUserId = localStorage.getItem('userId');
        if (currentUserId !== storedUserId) {
          setConsent(false);
          setStoredUserId(currentUserId);
        }
      }, 1000);

      return () => clearInterval(interval); // Cleanup beim Unmounten
    }
  }, [storedUserId, consent]);

  const handleConsent = async () => {
    const userId = localStorage.getItem('userId');
    const consentData = {
      id: userId,
      consentGiven: true,
      consentTimestamp: new Date().toISOString(),
      version: '1.0',
      language: language,
    };

    try {
      const response = await fetch('https://noisi-back-qzk2.onrender.com/save-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(consentData),
      });

      if (response.ok) {
        setConsent(true);
        localStorage.setItem('language', language);
        window.location.reload();
      } else {
        console.error('Error saving consent:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving consent:', error);
    }
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  if (location.pathname === '/rechtliches' || consent) {
    return null;
  }

  if (isLoading) {
    return (
      <div style={styles.loadingContainer}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div style={styles.banner}>
      <div style={styles.content}>
        <p style={styles.text}>
          {language === 'DE' ? (
            <>
              Wir verwenden Cookies und ähnliche Technologien, um Ihre Erfahrung zu verbessern. Indem Sie auf "Akzeptieren" klicken, stimmen Sie unserer{' '}
              <a href='/rechtliches' style={styles.link}>Datenschutzerklärung und den AGBs</a> zu, einschließlich der Nutzung aller auf der Seite „Rechtliches“ beschriebenen Dienste.
            </>
          ) : (
            <>
              We use cookies and similar technologies to enhance your experience. By clicking "Accept", you agree to our{' '}
              <a href='/rechtliches' style={styles.link}>Privacy Policy and Terms of Service</a>, including the use of all services described on the "Rechtliches" page.
            </>
          )}
        </p>
        <div style={styles.controls}>
          <Select
            value={language}
            onChange={handleLanguageChange}
            variant="outlined"
            style={styles.select}
          >
            <MenuItem value="DE">Deutsch</MenuItem>
            <MenuItem value="EN">English</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsent}
            style={styles.button}
          >
            {language === 'DE' ? 'Akzeptieren' : 'Accept'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    color: '#fff',
    textAlign: 'center',
    padding: '20px',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
    maxWidth: '90%',
    boxSizing: 'border-box',
  },
  text: {
    marginBottom: '10px',
    fontSize: '16px',
    color: 'white',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'underline',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  },
  select: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #fff',
    fontWeight: 'bold',
    fontSize: '18px',
    cursor: 'pointer',
    height: '50px',
  },
  button: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '50px',
  },
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1001,
  },
  loadingText: {
    marginTop: '10px',
    fontSize: '18px',
  },
};

export { ConsentBanner, analytics };
