import React, { useState, useEffect } from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    // Überprüfen, ob das Gerät ein Touchscreen ist
    const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(hasTouchSupport);

    // Nur den Mausbewegungslistener hinzufügen, wenn es kein Touch-Gerät ist
    if (!hasTouchSupport) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (!hasTouchSupport) {
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  // Wenn es ein Touch-Gerät ist, wird der benutzerdefinierte Cursor nicht angezeigt
  if (isTouchDevice) {
    return null;
  }

  return (
    <div
      className="custom-cursor"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    />
  );
};

export default CustomCursor;
